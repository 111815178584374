import phones from '@/constants/phones';
const phoneLinkHtml = `<a href="${phones.contactCenterTel}">${phones.contactCenter}</a>`;

export default {
  BENEFIT_LIMIT_MODAL: {
    title: 'Unfortunatelly, you can’t request service',
    text: `<b>Your maximum benefit limit for the plan has been reached.</b> Please check our <a href="#" class="hs-link">Terms & Conditions</a> for more information. If any questions, please contact our call center ${phoneLinkHtml}.`,
  },
  SERVICE_LIMIT_MODAL: {
    title: 'Unfortunatelly, you can’t request service',
    text: `<b>You have reached the maximum number of devices</b> for which you can request service. Please check our <a href="#" class="hs-link">Terms & Conditions</a> for more information. If any questions, please contact our call center ${phoneLinkHtml}.`,
  },
  PREVIOUS_CLAIM_MODAL: {
    title: 'Is your new service request related to a previous one made for this same device?',
    text: 'We see that you’ve already requested service for this device in the last 90 days. Please let us know if this relates to your original request.',
  },
  CONNECT_CLAIM_MODAL: {
    title: `To reopen a previous service request, please call HomeServe at ${phoneLinkHtml}.`,
    text: 'A call center agent will need to make sure that this request is related to your original request. If it is, your service request will be reopened at that time.',
  },
};
