<template>
  <div class="request-service-btn">
    <hs-button v-bind="$attrs" @click="checkLimits">Request service</hs-button>
    <!-- LIMITS POPUP -->
    <hs-popup
      v-if="showLimitPopup"
      aria-labelledby="limits-modal-titile"
      aria-describedby="limits-modal-desc"
      confirm-button-text="Ok"
      @confirm="closeLimitsModal"
      @hide="closeLimitsModal"
    >
      <h1 class="hs-popup__title" id="limits-modal-titile">{{ modalData.title }}</h1>
      <div class="hs-popup__description" id="limits-modal-desc">
        <p v-html="modalData.content"></p>
      </div>
    </hs-popup>
    <!-- LIMITS POPUP END -->
    <!-- PREVIOUS CLAIM POPUP -->
    <hs-popup
      v-if="showPreviousClaimPopup"
      aria-labelledby="previous-claim-titile"
      aria-describedby="previous-claim-desc"
      @hide="showPreviousClaimPopup = false"
      decline-button-text="No, it’s a new request"
      confirm-button-text="Yes, it’s a related request"
      @decline="startClaim"
      @confirm="renderConnectClaimModal"
    >
      <h1 class="hs-popup__title" id="previous-claim-titile">
        {{ modalTexts.PREVIOUS_CLAIM_MODAL.title }}
      </h1>
      <div
        class="hs-popup__description request-service-btn__modal-gray-content"
        id="previous-claim-desc"
      >
        <p>{{ modalTexts.PREVIOUS_CLAIM_MODAL.text }}</p>
      </div>
    </hs-popup>
    <!-- PREVIOUS CLAIM POPUP END -->
    <!-- CONNECT CLAIM POPUP -->
    <hs-popup
      v-if="showConnectClaimPopup"
      aria-labelledby="previous-claim-titile"
      aria-describedby="previous-claim-desc"
      confirm-button-text="Ok, got it"
      @confirm="closeConnectClaimPopup"
      @hide="closeConnectClaimPopup"
    >
      <h1
        class="hs-popup__title"
        id="previous-claim-titile"
        v-html="modalTexts.CONNECT_CLAIM_MODAL.title"
      ></h1>
      <div
        class="hs-popup__description request-service-btn__modal-gray-content"
        id="previous-claim-desc"
      >
        <p>{{ modalTexts.CONNECT_CLAIM_MODAL.text }}</p>
      </div>
    </hs-popup>
    <!-- CONNECT CLAIM POPUP END -->
  </div>
</template>

<script>
import ModalsTexts from '@/constants/start_service_modal_texts';

export default {
  name: 'RequestServiceBtn',
  inheritAttrs: false,
  props: ['id', 'productLine'],
  data() {
    return {
      // popups
      showLimitPopup: false,
      showPreviousClaimPopup: false,
      showConnectClaimPopup: false,
      // limits
      benefitLimit: null,
      serviceLimit: null,
      modalData: {
        title: '',
        content: '',
      },
      previousClaimRef: '',
    };
  },
  computed: {
    modalTexts() {
      return ModalsTexts;
    },
  },
  methods: {
    startClaim() {
      const {params, query} = this.$route;
      const {id} = this;
      this.$router.push({
        name: 'StartAClaim',
        params: {id},
        query,
      });
    },
    renderConnectClaimModal() {
      this.showPreviousClaimPopup = false;
      this.showConnectClaimPopup = true;
    },
    closeLimitsModal() {
      this.showLimitPopup = false;
      this.modalData = {
        title: '',
        content: '',
      };
    },
    closeConnectClaimPopup() {
      this.showConnectClaimPopup = false;
    },
    checkLimits() {
      const {customerRef} = this.$route.query;
      return this.$store
        .dispatch('user/DECRYPT_PARAMS', {
          customerRef,
        })
        .then((decryptedData) => {
          this.$store
            .dispatch('myDevices/CHECK_90_DAYS_CLAIMS_FOR_DEVICE', {
              customerRef: decryptedData?.customerRef,
              equipmentId: this.id,
              context: 'WEB',
              productLine: this.productLine,
            })
            .then((res) => {
              if (res && (res?.eligibility === false || res?.eligibility === 'false')) {
                this.previousClaimRef = res?.serviceJobId;
                this.showPreviousClaimPopup = true;
              } else if (res && (res?.eligibility === true || res?.eligibility === 'true')) {
                this.startClaim();
              }
            });
        });

      // TODO: if we are going to have benefit/service limits endpoints
      // re-enable this logic
      // return Promise.all([benefitPromise, servicePromise]).then((resp) => {
      //   const benefitPromise = this.$store.dispatch('user/CHECK_BENEFIT_LIMIT');
      //   const servicePromise = this.$store.dispatch('user/CHECK_SERVICE_LIMIT');
      //   if (!this.benefitLimit && !this.serviceLimit) {

      //   } else {
      //     if (this.benefitLimit) {
      //       this.modalData.title = this.modalTexts.BENEFIT_LIMIT_MODAL.title;
      //       this.modalData.content = this.modalTexts.BENEFIT_LIMIT_MODAL.text;
      //     }
      //     if (this.serviceLimit) {
      //       this.modalData.title = this.modalTexts.SERVICE_LIMIT_MODAL.title;
      //       this.modalData.content = this.modalTexts.SERVICE_LIMIT_MODAL.text;
      //     }
      //     this.showLimitPopup = true;
      //   }
      // });
    },
  },
};
</script>

<style lang="scss">
.limits-modal__right-sided-btn {
  margin-left: auto;
  .hs-btn {
    padding: 0 35px;
  }
}
.request-service-btn__modal-gray-content {
  color: $darkGray;
}
</style>
