<template>
  <svg
    :width="width"
    :height="height"
    viewBox="0 0 16 15"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <line
      x1="1.25"
      y1="-1.25"
      x2="17.3855"
      y2="-1.25"
      transform="matrix(-0.716495 0.697592 -0.716495 -0.697592 13.4062 0)"
      stroke="#E14106"
      stroke-width="2.5"
      stroke-linecap="round"
    />
    <line
      x1="1.25"
      y1="-1.25"
      x2="17.3855"
      y2="-1.25"
      transform="matrix(-0.716495 -0.697592 0.716495 -0.697592 15.1855 13)"
      stroke="#E14106"
      stroke-width="2.5"
      stroke-linecap="round"
    />
  </svg>
</template>

<script>
export default {
  props: {
    width: {
      type: [Number, String],
      default: 18,
    },
    height: {
      type: [Number, String],
      default: 18,
    },
  },
};
</script>
