<template>
  <svg
    :width="width"
    :height="height"
    viewBox="0 0 18 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      :fill="fillColor"
      d="M9 0C4.03748 0 0 4.03748 0 9C0 13.9625 4.03748 18 9 18C13.9625 18 18 13.9625 18 9C18 4.03748 13.9625 0 9 0ZM10.5 14.625C10.5 14.8323 10.3323 15 10.125 15H7.875C7.66772 15 7.49999 14.8323 7.49999 14.625V9V8.40234C7.49999 8.20898 7.49999 8.24048 7.49999 8.0332V7.25977C7.49999 7.05248 7.60546 6.74996 7.92773 6.74996H10.125C10.3323 6.74996 10.5 6.9177 10.5 7.12498V14.625ZM9 6.00001C8.17274 6.00001 7.49999 5.3273 7.49999 4.5C7.49999 3.6727 8.17274 2.99999 9 2.99999C9.82726 2.99999 10.5 3.6727 10.5 4.5C10.5 5.3273 9.82726 6.00001 9 6.00001Z"
    />
  </svg>
</template>

<script>
export default {
  props: {
    fillColor: String,
    width: {
      type: [Number, String],
      default: 18,
    },
    height: {
      type: [Number, String],
      default: 18,
    },
  },
};
</script>
