<template>
  <div class="device-verification-status">
    <template v-if="status === 'Pending'">
      <div class="device-verification-status__pending">
        <InfoIcon
          width="18"
          height="18"
          fill-color="#8D7146"
          class="device-verification-status__pending-icon"
        />
        <span>Your device registration is pending</span>
      </div>
    </template>
    <template v-if="status === 'Rejected'">
      <div class="device-verification-status__declined">
        <CrossIcon width="15" height="13" class="device-verification-status__declined-icon" />
        <span
          >We don't recognize this device based on the brand name and model number you provided.
          Please start a new registration or call
          <a :href="phones.contactCenterTel">{{ phones.contactCenter }}</a>
          for assistance. We recommend you
        </span>
        <button class="hs-link" @click.stop.prevent="removeDevice">Remove This Device</button>
      </div>
    </template>
  </div>
</template>

<script>
import InfoIcon from '@/components/shared/InfoIcon';
import CrossIcon from '@/components/shared/CrossIcon';
import phones from '@/constants/phones';

export default {
  components: {
    InfoIcon,
    CrossIcon,
  },
  props: {
    status: String,
    id: [String, Number],
    productLine: [String],
  },
  computed: {
    phones: () => phones,
  },
  methods: {
    removeDevice() {
      const {customerRef} = this.$route.query;
      this.$store
        .dispatch('user/DECRYPT_PARAMS', {
          customerRef,
        })
        .then((resp) => {
          const {id, productLine} = this;
          this.$store
            .dispatch('device/REMOVE_DEVICE', {
              id,
              productLine,
              customerRef: resp.customerRef,
            })
            .then(() => {
              this.$emit('deviceRemoved', this.id);
            });
        });
    },
  },
};
</script>

<style scoped lang="scss">
.device-verification-status {
  &__pending {
    display: flex;
    align-items: center;
    padding: 11px 15px;
    color: #8d7146;
    font-size: 18px;
    background-color: #fcf8e3;

    @include mf($gridMDBreakpoint) {
      padding: 8px 10px;
    }
  }
  &__declined {
    padding: 11px 15px;
    font-size: 16px;

    @include mf($gridMDBreakpoint) {
      padding: 0;
      padding-left: 10px;
      font-size: 18px;
    }
    button {
      margin-left: 5px;
    }
  }
  &__pending-icon,
  &__declined-icon {
    margin-right: 5px;
  }
}
</style>
