<template>
  <div class="device-claim">
    <div
      class="device-claim__base-info-cont"
      :class="[row ? 'device-claim__base-info-cont--row' : '']"
    >
      <p class="device-claim__id">{{ IDText }}</p>
      <span v-if="showServiceType" class="device-claim__devider"> | </span>
      <p v-if="showServiceType" class="device-claim__type">{{ typeText }}</p>
    </div>

    <div class="device-claim__status-cont" :class="[row ? 'device-claim__status-cont--row' : '']">
      <p class="device-claim__status">{{ statusText }}</p>

      <router-link v-if="!row" :to="showClaimLink" class="hs-link device-claim__details-link"
        >Details</router-link
      >
    </div>

    <div v-if="row" class="device-claim__status-tag-cont">
      <ClaimStatusTag
        :status="claim.serviceJobStatus"
        :type="claim.serviceJobType"
        class="device-claim__status-tag"
      />
    </div>
  </div>
</template>

<script>
import claimTypesNames from '@/constants/claim_types_names';
import ClaimStatusTag from '@/components/shared/ClaimStatusTag';
import jobStatusTextMixin from '@/mixins/jobStatusTextMixin';

export default {
  props: {
    claim: Object,
    row: {
      type: Boolean,
      deafault: false,
    },
  },
  components: {
    ClaimStatusTag,
  },
  mixins: [jobStatusTextMixin],
  computed: {
    showClaimLink() {
      const {query} = this.$route;
      const {serviceJobID} = this.claim;
      return {name: 'ShowJob', params: {id: serviceJobID}, query};
    },
    IDText() {
      return `Job number #${this.claim.serviceJobID}`;
    },
    typeText() {
      return (
        claimTypesNames[this.claim.serviceJobType] ||
        claimTypesNames[this.claim.serviceJobType.toUpperCase()]
      );
    },
    showServiceType() {
      return this.claim.serviceJobStatus !== 'CANCELLED';
    },
    statusText() {
      return this.getJobStatusText(this.claim);
    },
  },
};
</script>

<style lang="scss" scoped>
.device-claim {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  padding: 14px 0 16px;

  @include mf($gridMDBreakpoint) {
    flex-wrap: nowrap;
    padding: 24px 0;
    font-size: 18px;
  }
  &:not(:last-child) {
    border-bottom: 1px solid #dfdfdf;
  }
  &__base-info-cont {
    display: block;
    order: 1;
    font-size: 14px;
    &--row {
      width: calc(100% - 135px);

      @include mf($gridMDBreakpoint) {
        width: calc(250px + 25%);
      }
    }

    @include mf($gridMDBreakpoint) {
      display: flex;
      margin-right: auto;
      font-size: 16px;
    }
  }
  &__id {
    flex-shrink: 0;
  }
  &__devider {
    display: none;
    margin: 0 8px;

    @include mf($gridMDBreakpoint) {
      display: inline-block;
    }
  }
  &__type {
    color: $mediumGray;
  }
  &__status-cont {
    display: flex;
    order: 3;
    width: 100%;
    margin-top: 13px;

    @include mf($gridMDBreakpoint) {
      width: initial;
      margin-top: 0;
    }

    &--row {
      flex-grow: 1;
    }
  }
  &__status {
    color: $darkGray;
    font-weight: 700;
    font-size: 18px;

    @include mf($gridSMBreakpoint) {
      font-size: 16px;
    }

    @include mf($gridMDBreakpoint) {
      flex-grow: 1;
      order: 2;
      // width: auto;
      margin-top: 0;
      margin-right: 15px;
    }
    &:not(:empty) {
      margin-right: auto;

      @include mf($gridMDBreakpoint) {
        margin-right: 15px;
      }
    }
  }
  &__status-tag-cont {
    display: flex;
    justify-content: flex-end;
    order: 1;
    width: 135px;
    margin-left: auto;

    @include mf($gridMDBreakpoint) {
      order: 3;
      margin-left: 0;
    }
  }
  &__status-tag {
    width: 112px;
  }
  &__details-link {
    display: flex;
    align-items: center;
    order: 3;
    font-size: 14px;

    @include mf($gridSMBreakpoint) {
      font-size: 16px;
    }
    &::after {
      display: block;
      width: 9px;
      height: 6px;
      margin-left: 3px;
      background: url(~@/assets/images/nav-back.svg) no-repeat 0 0/9px 6px;
      transform: rotate(180deg);
      content: '';
    }
  }
}
</style>
